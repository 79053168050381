<template>
  <div>
    <transition :name="transition">
      <div
        ref="popupBase"
        v-show="show"
        class="popup"
        :class="[cssClass, { show: show }, size]"
        @keydown.esc="closePopUp($event, true)"
        @keydown="trapFocus($event)"
        @click="closePopUp"
        tabindex="-1"
        aria-hidden="true"
        :style="{ 'z-index': popupZIndex }"
      >
        <div
          ref="popup_wrapper"
          class="popup__wrapper"
          :class="{ 'second-layer': level === 2, 'second-layer-show': level === 2 && show }"
        >
          <div
            class="popup__content"
            :class="contentCssClass"
          >
            <a
              href="javascript:void(0)"
              class="close"
              :class="{ 'disable-close': topLayerOpen && level !== 2}"
              @click.prevent="closePopUp($event, true)"
            >
              <NavigationClose />
            </a>
            <slot />
          </div>
        </div>
      </div>
    </transition>
    <transition :name="transition">
      <div
        v-if="show"
        class="popup--backdrop"
        :style="{ 'z-index': backdropZIndex }"
      />
    </transition>
  </div>
</template>

<script>
import {POPUP} from '~/assets/js/constants'
import NavigationClose from '~/assets/icons/navigation_close.svg?component'

export default {
  components: {NavigationClose},
  props: {
    show: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    cssClass: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    contentCssClass: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    transition: {
      type: String,
      required: false,
      default() {
        return 'fade'
      }
    },
    closeOnOutsideClick: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    level: {
      type: Number,
      required: false,
      default() {
        return 0
      }
    },
    size: {
      type: String,
      required: false,
      default() {
        return POPUP.Size.large
      }
    }
  },
  data() {
    const popupZIndex = POPUP.ZIndex + this.level * 2
    return {
      backdropZIndex: popupZIndex - 1,
      popupZIndex: popupZIndex,
      topLayerOpen: false
    }
  },
  methods: {
    checkSecondLayerOpen() {
      this.topLayerOpen = document.body.getElementsByClassName('second-layer-show').length > 0
    },
    closePopUp(event, forceClose = false) {
      if (
        !forceClose &&
        (!this.closeOnOutsideClick || event.target !== event.currentTarget)
      ) {
        return
      }
      this.$emit('close-pop-up', true)
    },
    removeHtmlBodyOptions() {
      if (!window) {
        return
      }
      document.body.classList.remove('popup--open')
      document.body.style.paddingRight = ''
    },
    getScrollbarWidth() {
      // Creating invisible container
      const outer = document.createElement('div')
      outer.style.visibility = 'hidden'
      outer.style.overflow = 'scroll' // forcing scrollbar to appear
      outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps
      document.body.appendChild(outer)

      // Creating inner element and placing it in the container
      const inner = document.createElement('div')
      outer.appendChild(inner)

      // Calculating difference between container's full width and the child width
      const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

      // Removing temporary elements from the DOM
      outer.parentNode.removeChild(outer)

      return scrollbarWidth
    },
    scrollbarVisible(element) {
      return element.scrollHeight > element.clientHeight
    },
    trapFocus(event) {
      const allFocusableElements = this.$refs.popupBase.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), [contenteditable]'
      )
      const focusableElements = Array.from(allFocusableElements).filter(
        element => element.offsetParent !== null
      )
      const firstElement = focusableElements[0]
      const lastElement = focusableElements[focusableElements.length - 1]

      if (event.key === 'Tab') {
        if (focusableElements.length === 0) {
          event.preventDefault()
          return
        }

        if (event.shiftKey) {
          // Shift + Tab, move focus to the previous element
          if (document.activeElement === firstElement) {
            event.preventDefault()
            lastElement.focus()
          }
        } else if (document.activeElement === lastElement) {
          event.preventDefault()
          firstElement.focus()
        }
      }
    }
  },
  beforeUnmount() {
    this.removeHtmlBodyOptions()
  },
  mounted() {
    this.$eventbus.on('showPopup', () => this.checkSecondLayerOpen())
    this.$eventbus.on('closePopup', () => this.checkSecondLayerOpen())
  },
  watch: {
    show() {
      if (this.show) {
        this.$nextTick().then(() => {
          const scrollWidth = this.getScrollbarWidth()
          document.body.style.paddingRight = scrollWidth + 'px'
          document?.body?.classList?.add('popup--open')
          this.$refs.popup_wrapper?.focus()
          if (this.$refs.popup_wrapper) {
            this.$refs.popup_wrapper.scrollTop = 0
          }
          this.$eventbus.emit('showPopup')
        })
      } else {
        this.$nextTick().then(() => {
          this.$eventbus.emit('closePopup')
          const popupIsOpen =
            document.body.getElementsByClassName('popup show').length > 0
          if (!popupIsOpen) {
            this.removeHtmlBodyOptions()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.slide-leave-active,
.slide-enter-active {
  transition: .25s;
}

.slide-enter {
  transform: translate(0, -100%);
}

.slide-leave-to {
  transform: translate(0, -100%);
}

.fade-leave-active,
.fade-enter-active {
  transition: .25s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.popup,
.popup--backdrop {
  position: fixed;
  inset: 0;
}

.popup {
  &--open {
    overflow: hidden;

    .popup {
      overflow: hidden scroll;
    }
  }

  &--backdrop {
    z-index: 499;
    background-color: $color__black;
    opacity: .3;
  }

  z-index: 500;
  overflow: hidden;
  outline: 0;

  &__wrapper {
    background-color: $color__white;
    box-shadow: 0 .188rem .375rem $color--nav__main--background;
    max-width: 74.25rem;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    pointer-events: none;
    position: static;
    margin: 7rem auto $default-margin-padding-2x;

    @include breakpoint-down($md) {
      overflow-x: hidden;
      margin: 0;
      inset: 0;
      min-height: 100%;
      min-width: 100%;
    }

    .popup__content {
      position: relative;
      pointer-events: auto;
      padding: 1.5rem $default-margin-padding-width;
    }
  }

  &.small {
    .popup__wrapper {
      max-width: 35rem;
      width: auto;
    }
  }

  &.medium {
    .popup__wrapper {
      max-width: 50rem;
    }
  }

  &.large {
    .popup__wrapper {
      max-width: 75rem;
    }
  }

  .close {
    font-size: 1.8em;
    color: $color__text__default;
    position: absolute;
    z-index: 11;
    right: .75rem;
    top: .75rem;

    &.disable-close {
      pointer-events: none;
    }
  }

  .second-layer  {
    margin: 14rem auto $default-margin-padding-2x;
  }
}
</style>
